import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from 'reactstrap/lib/Card';

import {
  IncentivesCreativeConfigDefaultProps,
  IncentivesCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';
import {
  CommonAdDefaultProps,
  CommonAdPropTypes,
  SiteServedAdDefaultProps,
  SiteServedAdPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { INCENTIVES_AD } from 'site-modules/shared/components/native-ad/utils/constants';
import { DfpTracking } from 'client/site-modules/shared/components/native-ad/utils/dfp-tracking';

import { AdLabel } from 'client/site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { Disclaimer } from 'client/site-modules/shared/components/native-ad/native-ad-components/disclaimer/disclaimer';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { addZipCode, definePlatform } from 'site-modules/shared/components/native-ad/utils/utils';
import {
  getDisplayHeadline,
  getTrackingValue,
} from 'site-modules/shared/components/native-ad/utils/offer-incentives-ad-utils';
import { siteServedAdTrackingWrapper } from 'site-modules/shared/components/native-ad/site-served-ad-tracking-wrapper/site-served-ad-tracking-wrapper';
import { ConquestRibbon } from 'site-modules/shared/components/native-ad/native-ad-components/conquest-ribbon/conquest-ribbon';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';

import { Styles } from './incentives-site-served-ad-styles';

const DEFAULT_HEADLINE = 'See Offers %%PATTERN:zip%%';

export function IncentivesSiteServedAdAds10735UI({
  adRef,
  adClickTrackingListener,
  adLoadTrackingListener,
  adTrackers,
  creativeConfig,
  customTargeting,
  mobile,
  position,
  cardWrapper,
  horizontalLayout,
  noBorder,
  ads10735Recipe,
}) {
  const {
    siteServedAdsUpdater,
    zipCode,
    ipZipCode,
    offersNumber,
    location,
    creativeConfigData: {
      logo,
      linkDisclaimer,
      disclaimerCopy,
      headline,
      headline2,
      linkDisplayUrl,
      cta,
      lineItemId,
      siteServedCreativeId,
      target,
      isConquest,
    },
    traffickingData: { clickTracker },
  } = creativeConfig;

  const loadTrackingListener = useCallback(
    event => {
      const trackingValue = getTrackingValue(headline, offersNumber, zipCode, ipZipCode, DEFAULT_HEADLINE, 15, 15);
      adLoadTrackingListener(event, trackingValue);
    },
    [adLoadTrackingListener, headline, ipZipCode, offersNumber, zipCode]
  );

  if (!logo || !cta) {
    return null;
  }

  const displayHeadline = getDisplayHeadline(
    headline,
    offersNumber,
    zipCode,
    ipZipCode,
    DEFAULT_HEADLINE,
    15,
    15,
    location
  );

  const ctaURL = addZipCode(clickTracker[definePlatform(mobile)], zipCode);
  const ctaTarget = target || '_blank';
  const nativeStyle = INCENTIVES_AD.NATIVE_STYLE;

  const linkDisplay = (
    <div className="text-blue-40 text-center" style={{ ...Styles.mt_0_25, ...Styles.disclaimer }}>
      {linkDisplayUrl}
    </div>
  );

  const style = noBorder ? { border: 'none' } : { overflow: 'hidden' };

  const conquestRibbonStyle = { ...Styles.conquestRibbon, marginLeft: noBorder ? '-16px' : 0 };
  const conquestRibbon = <ConquestRibbon style={conquestRibbonStyle} />;

  const isAds10735Enabled = ['chal1', 'chal2'].includes(ads10735Recipe); // 'ctrl' is the default;
  const isAds10735Chal1 = ads10735Recipe === 'chal1';
  const isAds10735Chal1Wired = isAds10735Chal1 && !mobile && !isConquest;
  const isAds10735Mobile = isAds10735Enabled && mobile;
  const cardStyleAds10735 = isAds10735Enabled
    ? {
        paddingBottom: isAds10735Chal1Wired ? '20px' : '12px',
        paddingTop: isAds10735Chal1Wired ? '20px' : '8px',
        borderRadius: '8px',
        border: 'none',
      }
    : style;
  const wiredHeadlineStyles = isAds10735Enabled ? Styles.headlineAds10735 : Styles.headline;
  const mobileHeadlineCtaStyles = isAds10735Mobile ? Styles.px_0_75 : Styles.px_1_5;
  const ads10735LinkStyle = isAds10735Enabled ? null : Styles.mb_0_5;

  return (
    <Card
      className={classnames('incentives-site-served pos-r w-100', cardWrapper)}
      data-tracking-parent={nativeStyle}
      data-viewability={`${nativeStyle}-${position}`}
      innerRef={adRef}
      style={cardStyleAds10735}
    >
      <DfpTracking
        lineItemId={lineItemId}
        siteServedCreativeId={siteServedCreativeId}
        mobile={mobile}
        nativeStyle={nativeStyle}
        position={position}
        customTargeting={customTargeting}
        slotRenderEndListener={loadTrackingListener}
        siteServedAdsUpdater={siteServedAdsUpdater}
      />
      {adTrackers}
      <a
        className="site-served-ad-link no-focus text-decoration-none d-block"
        href={ctaURL}
        target={ctaTarget}
        rel="noopener noreferrer"
        onClick={adClickTrackingListener}
      >
        {!noBorder && !isAds10735Enabled && <AdLabel />}
        {!isAds10735Chal1Wired && (
          <div
            className={classnames('w-100', {
              'd-flex justify-content-between align-items-center mb-0_5': noBorder,
            })}
            style={
              isAds10735Mobile
                ? {
                    marginTop: '4px',
                    marginBottom: '16px',
                  }
                : Styles.my_0_25
            }
          >
            {isConquest ? (
              conquestRibbon
            ) : (
              <img className={!noBorder ? 'ms-0_5' : null} style={Styles.oemLogo} alt="" src={logo} loading="lazy" />
            )}
            {noBorder && !isAds10735Enabled && <div style={{ fontSize: '10px', textAlign: 'right' }}>Sponsored</div>}
          </div>
        )}
        <div
          className={classnames({
            'flex-column': !horizontalLayout,
            'd-flex justify-content-between': !isAds10735Mobile,
          })}
        >
          {isAds10735Chal1Wired && (
            <div
              className={classnames({
                'd-flex justify-content-between align-items-center mb-0_5': noBorder,
                'ms-1 me-1_5 d-flex align-items-center': !isConquest,
              })}
              style={Styles.my_0_25}
            >
              <img className={!noBorder ? 'ms-0_5' : null} style={Styles.oemLogo} alt="" src={logo} loading="lazy" />
              {noBorder && <div style={{ fontSize: '10px', textAlign: 'right' }}>Sponsored</div>}
            </div>
          )}
          <div
            className={classnames({
              'mt-0_5': isAds10735Chal1Wired,
            })}
            style={isAds10735Chal1Wired ? { flex: '1 1 auto' } : { ...(noBorder ? {} : mobileHeadlineCtaStyles) }}
          >
            <div
              className={classnames('headline fw-bold mb-0_25', {
                'text-gray-darkest': !isAds10735Enabled,
                'text-cool-gray-10 ': isAds10735Enabled,
              })}
              style={{ ...Styles.mb_0_25, ...(mobile ? Styles.headline_mobile : wiredHeadlineStyles) }}
            >
              <ContentFragment>{displayHeadline}</ContentFragment>
            </div>
            <div
              className={classnames('small', {
                'text-gray-darker': !isAds10735Enabled,
                'text-cool-gray-30': isAds10735Enabled,
              })}
              style={
                isAds10735Enabled
                  ? {
                      fontSize: '1rem',
                      lineHeight: '1.375rem',
                    }
                  : Styles.headline2
              }
            >
              <ContentFragment>{headline2}</ContentFragment>
            </div>
          </div>
          <div
            style={{
              ...Styles.alignSelfStart,
              ...(noBorder ? {} : mobileHeadlineCtaStyles),
              ...(!horizontalLayout ? Styles.mt_0_75 : {}),
              ...(linkDisplayUrl ? ads10735LinkStyle : Styles.withoutLinkDisplayUrl[mobile]),
            }}
            className={classnames({
              'w-100': (noBorder && !isAds10735Enabled) || isAds10735Mobile,
            })}
          >
            <div
              className={classnames('btn text-nowrap text-transform-none size-16', {
                'py-0_5 px-0_75': !isAds10735Enabled,
                'btn-blue-50': (!noBorder && !isConquest) || isAds10735Enabled,
                'btn-outline-blue-50': !noBorder && isConquest,
                'btn-outline-blue-50 w-100': noBorder && !isAds10735Enabled,
                'py-0_75 px-1': isAds10735Enabled,
                'w-100 mt-1_25': isAds10735Mobile,
              })}
              style={
                isAds10735Enabled
                  ? { ...Styles.fontWeight500, ...Styles.py_0_75 }
                  : { ...Styles.fontWeightNormal, ...Styles.py_0_75 }
              }
            >
              <CtaButtonText text={cta} />
            </div>
            {linkDisplay}
          </div>
        </div>
      </a>
      {disclaimerCopy && <Disclaimer linkDisclaimer={linkDisclaimer} disclaimerText={disclaimerCopy} />}
    </Card>
  );
}

IncentivesSiteServedAdAds10735UI.propTypes = {
  ...SiteServedAdPropTypes,
  customTargeting: CommonAdPropTypes.customTargeting,
  creativeConfig: IncentivesCreativeConfigPropTypes,
  cardWrapper: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  noBorder: PropTypes.bool,
  vehicle: PropTypes.shape({}),
  ads10735Recipe: PropTypes.string,
};

IncentivesSiteServedAdAds10735UI.defaultProps = {
  ...SiteServedAdDefaultProps,
  customTargeting: CommonAdDefaultProps.customTargeting,
  creativeConfig: IncentivesCreativeConfigDefaultProps,
  cardWrapper: null,
  horizontalLayout: false,
  noBorder: false,
  vehicle: {},
  ads10735Recipe: null,
};

export const IncentivesSiteServedAdAds10735 = siteServedAdTrackingWrapper(IncentivesSiteServedAdAds10735UI);
