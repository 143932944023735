import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';

import {
  AdComponentDefaultProps,
  AdComponentPropTypes,
} from 'site-modules/shared/components/ad-unit/ad-unit-prop-types';
import { IncentivesSiteServedAd } from 'client/site-modules/shared/components/native-ad/incentives-site-served-ad/incentives-site-served-ad';
import { IncentivesSiteServedAdAds10735 } from 'client/site-modules/shared/components/native-ad/incentives-site-served-ad/incentives-site-served-ad-ads-10735';

import { NativeAd } from 'site-modules/shared/components/ad-unit/native-ad';
import { INCENTIVES_AD } from 'client/site-modules/shared/components/native-ad/utils/constants';
import { withSiteServedAdWrapper } from 'client/site-modules/shared/components/native-ad/site-served-ad-wrapper/site-served-ad-wrapper';
import {
  IncentivesCreativeConfigDefaultProps,
  IncentivesCreativeConfigPropTypes,
} from 'client/data/models/native-ads-creative-config';

import './incentives-ad.scss';

const DATA_TEST = 'incentives-ad';

export function IncentivesAdUI(props) {
  const {
    wrapperClass,
    cardWrapper,
    mobile,
    slotRenderEndListener,
    includeMrectAdCall,
    withDFPFallback,
    withSiteServed,
    siteServedAdsEnabled,
    position,
    creativeConfig,
    currentVehicle,
    vehicle,
    placeholderComponent,
    placeholderComponentProps,
    customTargeting,
    excludeFilmstrip,
    horizontalLayout,
    noBorder,
    fallbackNativeStyle,
    ivtClassName,
    showDropshadow,
    ads10735Recipe,
    ads10773Recipe,
  } = props;

  const isAds10735Enabled = ['chal1', 'chal2'].includes(ads10735Recipe);

  const SiteServedIncentive = (
    <div data-test={DATA_TEST} className={classnames(wrapperClass, ivtClassName, { 'd-none': !withSiteServed })}>
      {withSiteServed &&
        (isAds10735Enabled ? (
          <IncentivesSiteServedAdAds10735
            cardWrapper={cardWrapper}
            mobile={mobile}
            position={position}
            creativeConfig={creativeConfig}
            vehicle={currentVehicle || vehicle}
            customTargeting={customTargeting}
            horizontalLayout={horizontalLayout}
            noBorder={noBorder}
            ads10735Recipe={ads10735Recipe}
          />
        ) : (
          <IncentivesSiteServedAd
            cardWrapper={cardWrapper}
            mobile={mobile}
            position={position}
            creativeConfig={creativeConfig}
            vehicle={currentVehicle || vehicle}
            customTargeting={customTargeting}
            horizontalLayout={horizontalLayout}
            noBorder={noBorder}
            showDropshadow={showDropshadow}
            ads10773Recipe={ads10773Recipe}
          />
        ))}
    </div>
  );

  const nativeAdProps = {
    customTargeting,
    refreshDisableWhenZipChanged: siteServedAdsEnabled,
    placeholderComponent,
    placeholderComponentProps,
    position,
    slotRenderEndListener,
    siteServedAdsUpdater: get(creativeConfig, 'siteServedAdsUpdater'),
  };

  const adsWithDFPFallback = (
    <NativeAd
      wiredBreakpoints={{
        lg: true,
        xl: true,
      }}
      nativeStyle="vdp"
      {...nativeAdProps}
      useMinHeight
    />
  );

  const adsWithDFPFallbackFilmstrip = (
    <NativeAd
      wiredBreakpoints={{
        lg: true,
        xl: true,
      }}
      nativeStyle={fallbackNativeStyle}
      {...nativeAdProps}
      useMinHeight
    />
  );

  const adsWithDFPFallbackWrapper = excludeFilmstrip ? adsWithDFPFallback : adsWithDFPFallbackFilmstrip;
  const renderDfpFallback = (
    <div
      className={classnames('incentive-ad', wrapperClass, ivtClassName, { placeholder: !!placeholderComponent })}
      data-test={DATA_TEST}
    >
      {includeMrectAdCall ? (
        <NativeAd
          adNameWired="mrect,native"
          adNameMobile="mmrect,mnative"
          wiredBreakpoints={{
            lg: true,
            xl: true,
          }}
          nativeStyle="vdp,mediumrectangle"
          customSizes={[[1, 1], [300, 250], 'fluid']}
          {...nativeAdProps}
        />
      ) : (
        adsWithDFPFallbackWrapper
      )}
    </div>
  );

  if (isAds10735Enabled) {
    return (
      <Fragment>
        {/* To make it only show site served ads, showOnlySiteServed props need to be passed and set to true in order to make withDFPFallback=false */}
        {withDFPFallback ? (
          <Fragment>
            <div className="mb-2_5">
              <span className="text-cool-gray-10 size-12 float-end">Ad</span>
            </div>
            {renderDfpFallback}
          </Fragment>
        ) : (
          <Fragment>
            <div className="mb-1">
              <h2 className="heading-4 d-inline-block mb-0">Incentives</h2>
              <span className="text-cool-gray-10 size-12 float-end">Ad</span>
            </div>
            {SiteServedIncentive}
          </Fragment>
        )}
      </Fragment>
    );
  }
  return (
    <Fragment>
      {/* To make it only show site served ads, showOnlySiteServed props need to be passed and set to true in order to make withDFPFallback=false */}
      {withDFPFallback ? renderDfpFallback : SiteServedIncentive}
    </Fragment>
  );
}

IncentivesAdUI.propTypes = {
  ...AdComponentPropTypes,
  creativeConfig: IncentivesCreativeConfigPropTypes,
  cardWrapper: PropTypes.string,
  includeMrectAdCall: PropTypes.bool,
  excludeFilmstrip: PropTypes.bool,
  horizontalLayout: PropTypes.bool,
  noBorder: PropTypes.bool,
  fallbackNativeStyle: PropTypes.string,
  showOnlySiteServed: PropTypes.bool,
  showDropshadow: PropTypes.bool,
  ads10735Recipe: PropTypes.string,
  ads10773Recipe: PropTypes.string,
};

IncentivesAdUI.defaultProps = {
  ...AdComponentDefaultProps,
  creativeConfig: IncentivesCreativeConfigDefaultProps,
  cardWrapper: null,
  includeMrectAdCall: false,
  excludeFilmstrip: false,
  horizontalLayout: false,
  noBorder: false,
  fallbackNativeStyle: 'vdp,filmstripincentive',
  showOnlySiteServed: false,
  showDropshadow: false,
  ads10735Recipe: '',
  ads10773Recipe: null,
};

const IncentivesAdWrapper = withSiteServedAdWrapper(IncentivesAdUI);

export function IncentivesAd(props) {
  return <IncentivesAdWrapper {...props} adType={INCENTIVES_AD} />;
}
